import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    IconButton,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
    Divider,
    Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { ro } from 'date-fns/locale';
import { set } from 'date-fns';
import useQueues from "../../hooks/useQueues";


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    addButton: {
        marginTop: theme.spacing(2),
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'row', // Deixa os rádios em linha
    },
    btnContainer: {
        textAlign: "right",

    },
}));

const RoutineSchema = Yup.object().shape({
    messages: Yup.array().of(
        Yup.object().shape({
            message: Yup.string().required('Mensagem é obrigatória'),
            timeBefore: Yup.string().required('Tempo é obrigatório'),
        })
    ),
});

const AgendaRoutineModal = ({ open, handleClose, routineId, edit }) => {
    const classes = useStyles();


    const initialValues = {
        title: '',
        queue: '',
        statusTicket: 'pending',
        messages: [
            {
                message: '',
                timeBefore: '',
                timeUnit: '',
                confirmation: '',
            },
        ],
    };

    const [timeUnitCurrent, setTimeUnitCurrent] = useState(null);
    const [confirmationCurrent, setConfirmationCurrent] = useState(null);

    const [routine, setRoutine] = useState(initialValues);
    const [loading, setLoading] = useState(false);

    const { findAll: findAllQueues } = useQueues();
    const [queues, setQueues] = useState([]);

    useEffect(() => {
        setLoading(true);

        if (!routineId) {
            setRoutine(initialValues);
        }

        if (routineId) {
            (async () => {
                try {
                    const { data } = await api.get(`/agendas/routine/${routineId}`);
                    setRoutine(prevState => ({ ...prevState, ...data, queue: data.queueId }));

                } catch (error) {
                    console.log(error);
                    toast.error('Erro ao carregar rotina!');
                }
            })();
        }

        const loadQueues = async () => {
            const list = await findAllQueues();
            setQueues(list);
        }

        loadQueues();
        setLoading(false);


    }, [open, routineId]);

    const handleSubmit = async (values) => {

        console.log(values);

        try {

            if (routineId) {
                const response = await api.put(`/agendas/routine/${routineId}`, values);
                console.log(response.data);
                toast.success('Rotina atualizada com sucesso!');
            } else {

                const response = await api.post('/agendas/routine', values);
                console.log(response.data);

                toast.success('Rotina cadastrada com sucesso!');
            }

        } catch (error) {
            console.log(error);
            toast.error('Erro ao cadastrar rotina!');
        }

        handleClose();
    };



    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
            <DialogTitle id="form-dialog-title">{edit ? 'Editar' : 'Criar'} Rotina</DialogTitle>
            {loading && <DialogContent>Carregando...</DialogContent>}
            {!loading && (
                <DialogContent dividers>
                    <Formik
                        initialValues={routine}
                        enableReinitialize={true}
                        validationSchema={RoutineSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSubmit(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                            <Form>
                                <div style={{ marginBottom: 10 }}>
                                    <Field
                                        name="title"
                                        as={TextField}
                                        label="Título"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        onChange={(e) => {
                                            setFieldValue('title', e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.title}
                                        error={touched.title && Boolean(errors.title)}
                                        helperText={touched.title && errors.title}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginBottom: 10,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}>
                                    <div style={{ width: "50%" }}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: "1rem" }}
                                        >Fila de Atendimento para o Ticket
                                        </Typography>
                                        <Field
                                            name="queue"
                                            as={Select}
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            onChange={(e) => {
                                                setFieldValue('queue', e.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.queue}
                                            placeholder="Selecione a fila"
                                            error={touched.queue && Boolean(errors.queue)}
                                            helperText={touched.queue && errors.queue}
                                        >
                                            {queues.map((queue) => (
                                                <option
                                                    key={queue.id}
                                                    value={queue.id}
                                                    selected={values.queue === queue.id}
                                                >{queue.name}</option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div style={{ width: "50%", marginLeft: 10 }}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: "1rem" }}
                                        >Status do Ticket após envio das mensagens
                                        </Typography>
                                        <RadioGroup
                                            name="statusTicket"
                                            value={values.statusTicket}
                                            onChange={(e) => {
                                                setFieldValue('statusTicket', e.target.value);
                                            }}
                                            className={classes.radioGroup}
                                        >
                                            <FormControlLabel
                                                value="open"
                                                control={<Radio />}
                                                label="Atendendo"
                                            />
                                            <FormControlLabel
                                                value="pending"
                                                control={<Radio />}
                                                label="Aguardando"
                                            />
                                            <FormControlLabel
                                                value="closed"
                                                control={<Radio />}
                                                label="Resolvidos"
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>

                                <Divider style={{ margin: "20px 0" }} />
                                <FieldArray name="messages">
                                    {({ insert, remove, push }) => (
                                        <div>
                                            {values.messages.map((_, index) => (
                                                <div key={index}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Field
                                                                name={`messages[${index}].message`}
                                                                as={TextField}
                                                                label="Mensagem"
                                                                fullWidth
                                                                variant="outlined"
                                                                multiline
                                                                rows={5}
                                                                margin="dense"
                                                                onChange={(e) => {
                                                                    setFieldValue(`messages[${index}].message`, e.target.value);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.messages[index].message}
                                                                error={touched?.messages?.[index]?.message && Boolean(errors?.messages?.[index]?.message)}
                                                                helperText="Tags para personalização: {{name}} = nome do contato, {{ms}} = saudação (bom dia, boa tarde...), 
                                                                {{agenda}} = nome da agenda, {{{eventdate}}} = data do agendamento e 
                                                                {{eventhour}} = horário do agendamento"
                                                            />
                                                        </Grid>
                                                        <Grid item container xs={7} direction="row">

                                                            <Grid item>
                                                                <Field
                                                                    name={`messages[${index}].timeBefore`}
                                                                    as={TextField}
                                                                    label="Tempo para o envio"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    onChange={(e) => {
                                                                        setFieldValue(`messages[${index}].timeBefore`, e.target.value);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    value={values.messages[index].timeBefore}
                                                                    error={touched?.messages?.[index]?.timeBefore && Boolean(errors?.messages?.[index]?.timeBefore)}
                                                                    helperText={touched?.messages?.[index]?.timeBefore && errors?.messages?.[index]?.timeBefore}
                                                                />
                                                            </Grid>
                                                            <Grid item style={{ marginLeft: 10 }}>
                                                                <RadioGroup
                                                                    name={`messages[${index}].timeUnit`}
                                                                    value={values.messages[index].timeUnit}
                                                                    onChange={(e) => {
                                                                        setFieldValue(`messages[${index}].timeUnit`, e.target.value);
                                                                    }}
                                                                    className={classes.radioGroup}
                                                                >
                                                                    <FormControlLabel
                                                                        value="minutos"
                                                                        control={<Radio />}
                                                                        label="minutos"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="horas"
                                                                        control={<Radio />}
                                                                        label="horas"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="dias"
                                                                        control={<Radio />}
                                                                        label="dias"
                                                                    />
                                                                </RadioGroup>
                                                                {touched?.messages?.[index]?.timeUnit && errors?.messages?.[index]?.timeUnit && (
                                                                    <div style={{ color: 'red' }}>{errors.messages[index].timeUnit}</div>
                                                                )}
                                                            </Grid>

                                                        </Grid>
                                                        <Grid item container xs={12} direction="row">
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ fontSize: "1rem" }}
                                                                >Precisa de confirmação?
                                                                </Typography>
                                                                <Field
                                                                    name={`messages[${index}].confirmation`}
                                                                    as={Select}
                                                                    label="Precisa de confirmação"
                                                                    size="small"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    onChange={(e) => {
                                                                        setFieldValue(`messages[${index}].confirmation`, e.target.value);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    value={values.messages[index].confirmation}
                                                                    error={touched?.messages?.[index]?.confirmation && Boolean(errors?.messages?.[index]?.confirmation)}
                                                                    helperText={touched?.messages?.[index]?.confirmation && errors?.messages?.[index]?.confirmation}
                                                                >
                                                                    <option
                                                                        value="true"
                                                                        selected={values.messages[index].confirmation === 'true'}
                                                                    >Sim</option>
                                                                    <option
                                                                        value="false"
                                                                        selected={values.messages[index].confirmation === 'false'}
                                                                    >Não</option>
                                                                </Field>
                                                            </Grid>
                                                            <Grid item style={{ marginLeft: "auto" }}>
                                                                <IconButton
                                                                    size="small"
                                                                    color="secondary"
                                                                    onClick={() => remove(index)}
                                                                    disabled={values.messages.length === 1} // Prevenir remoção se for o último
                                                                >
                                                                    <Typography variant="body2">Remover</Typography> <DeleteOutlineIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {index < values.messages.length - 1 && (<Divider style={{ margin: "20px 0" }} />)}
                                                </div>
                                            ))}

                                            <Divider style={{ marginTop: "10px" }} />
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() =>
                                                    push({
                                                        message: '',
                                                        timeBefore: '',
                                                        timeUnit: '',
                                                        confirmation: '',
                                                    })
                                                }
                                                startIcon={<AddIcon />}
                                                className={classes.addButton}
                                            >
                                                Mais Mensagem
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>

                                <DialogActions>
                                    <Button onClick={handleClose} color="secondary">
                                        Cancelar
                                    </Button>
                                    <Button type="submit" color="primary">
                                        Salvar
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default AgendaRoutineModal;